import {} from "prop-types";

const propTypes = {};

const NotFoundPage = (props) => {
  return <div>NotFoundPage</div>;
};

NotFoundPage.propTypes = propTypes;
export default NotFoundPage;
